<template>
  <div class="page-contacts">
    <ContentContainer class="page-contacts__container">
      <section class="page-contacts__section">
        <h2 class="page-contacts__title">About</h2>
        <p class="page-contacts__paragraph">
          HPLC Cloud is designed and developed by
          <a href="https://newcrom.com/newcrom/" class="page-contacts__link">Newcrom Inc.</a> and
          <a href="https://sielc.com/" class="page-contacts__link">Sielc Technologies</a>.
        </p>
      </section>

      <section class="page-contacts__section">
        <h2 class="page-contacts__title">Contacts</h2>
        <div class="page-contacts__contacts">
          <p class="page-contacts__paragraph">
            For direct contact, product information, technical support, partnership or other
            questions, please call Monday through Friday between the hours of 9:00 am – 6:00 pm CST,
            USA, or send us an email.<br />
            <br />
            Phone: <a href="tel:8472292629">+1 (847) 229-2629</a><br />
            Email: <a href="mailto:support@sielc.com">support@sielc.com</a>,
            <a href="mailto:sales@sielc.com">sales@sielc.com</a>
          </p>
          <img src="@/assets/media/sielc-logo.gif" alt="sielc" class="page-contacts__image-logo" />
        </div>
      </section>

      <section class="page-contacts__section">
        <h2 class="page-contacts__title">Contact us</h2>
        <Form />
      </section>
    </ContentContainer>
  </div>
</template>

<script>
  import ContentContainer from '@/prerender-pages/new/components/shared/ContentContainer';
  import Form from '@/prerender-pages/new/components/contactUs/Form';

  export default {
    name: 'Contacts',

    components: { Form, ContentContainer },
  };
</script>

<style lang="scss" scoped>
  .page-contacts {
    &__container {
      padding-top: 80px;
      padding-bottom: 80px;

      @media (max-width: $screen-md-max) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }

    &__section {
      margin-bottom: 64px;
    }

    &__title {
      font-size: $landing-size-lg + 4rem;
      font-weight: $weight-extra-bold;
      margin-bottom: 20px;
      letter-spacing: -3px;
    }

    &__paragraph {
      font-size: $landing-size-md;
      line-height: 1.5;
    }

    &__link {
      color: $color-text-primary;
      transition: color 0.3s, border-bottom-color 0.3s;
      border-bottom: 1px solid transparent;

      &:hover {
        color: $color-text-primary--hover;
        border-bottom-color: $color-text-primary--hover;
      }

      &:active {
        color: $color-text-primary--active;
        border-bottom-color: $color-text-primary--active;
      }
    }

    &__contacts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 110px;
      align-items: center;

      @media (max-width: $screen-md-max) {
        grid-template-columns: 1fr auto;
        gap: 30px;
      }

      @media (max-width: $screen-sm-max) {
        justify-items: center;
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }

    &__image-logo {
      width: 270px;
    }

    //&__contact-us {
    //}
  }
</style>
