<template>
  <div v-if="success" class="request-success">
    <div>Thank you!<br />We received your message.</div>
    <a href="#" class="request-success__send-again" @click="reset">send again</a>
  </div>
  <form v-else class="request-form" @submit.prevent="submit">
    <label>
      Message *
      <textarea
        v-model="form.message"
        :disabled="loading"
        cols="70"
        name="message"
        required
        rows="6"
      />
    </label>

    <label>
      Name
      <input v-model="form.name" :disabled="loading" name="name" type="text" />
    </label>

    <label>
      Email address *
      <input
        v-model="form.email"
        :disabled="loading"
        name="email"
        placeholder="reply@here.com"
        required
        type="email"
      />
    </label>

    <div v-if="error" class="request-form__error">{{ error }}</div>

    <button :disabled="loading" class="request-form__btn-submit" type="submit">Submit</button>
  </form>
</template>

<script>
  import { ContactUs } from 'api/contact-us';

  export default {
    name: 'Form',

    data: () => ({
      form: {},
      error: null,
      loading: false,
      success: false,
    }),

    methods: {
      submit() {
        ContactUs.post(
          this.form,
          () => {
            this.loading = false;
            this.error = null;
            this.success = true;
          },
          () => {
            this.error = 'Unable to send the message';
            this.loading = false;
          },
        );
        this.error = null;
        this.loading = true;
      },

      reset() {
        this.error = null;
        this.success = false;
        this.form.message = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  textarea,
  input {
    color: #000;
    background: #f0f0f0;
    border: none;
    border-radius: 2px;
    padding: 7px 0 7px 12px;
    width: 100%;
    display: block;
    outline: none !important;
    font-size: 13px;
    appearance: none;
    min-height: 29px;
  }

  .request-form {
    max-width: 375px;

    textarea {
      resize: none;
    }

    label,
    input,
    button {
      display: block;
      width: 100%;
    }

    label {
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.4);
      line-height: 1.45;
      font-size: $landing-size-xs;
    }

    &__btn-submit {
      font-size: 13px;
      background: #2a2398;
      color: #fff;
      border-radius: 3px;
      min-height: 31px;
      font-weight: $weight-medium;

      &:focus {
        background: #2a2398;
      }

      &:active:focus {
        background: #544db4;
      }

      &:disabled {
        background: #7c7c7c;
      }
    }

    &__error {
      margin-bottom: 16px;
      color: #ea1800;
    }
  }

  .request-success {
    line-height: 1.5;

    &__success-block {
      margin: 64px 0;
      color: #5eca00;
      text-align: center;
    }

    &__send-again {
      color: rgba(0, 0, 0, 0.42);

      &:hover {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }
</style>
